import { useRef, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'

import type { Swing } from 'src/Types/SwingTypes'
import type { Asset } from 'src/Types/AssetTypes'
import type { Alarm } from 'src/Types/Alarm'
import { MonitoringSystem } from 'src/Types/MonitoringSystem'

import { Alert } from 'src/Components/Alert'
import { useChartStyles } from 'src/Components/Chart/chartStyles'

import { SwitchChartRenderer } from './switchChartRenderer'
import { SwingLegend } from './SwingLegend'

import { useUserProfileStore } from 'src/Store/userProfile'
import { useDomainConstantsStore } from 'src/Store/domainConstants'

import { useChartResizeBehaviour } from 'src/Hooks/useChartResizeBehaviour'
import { useLogReactParams } from 'src/Hooks/useLogReactParams'
import { YRangeInputs } from 'src/Components/Chart/YRangeInputs/YRangeInputs'
import { YRangeInputsProvider } from 'src/Components/Chart/YRangeInputs/YRangeInputsProvider'
import type { YDomainSetter } from 'src/Components/Chart/YRangeInputs/YRangeInputsInterfaces'

const DEFAULT_ZOOM_LEVEL = [{ zoomHigh: 0, zoomLow: 0 }]

const StyledChartContainer = styled('div')(
  props => `
  margin: 10px;
  ${props.theme.breakpoints.down('sm')} {
    margin: 6px;
  }
`
)

const StyledChart = styled('div')`
  position: relative;
  overflow: hidden;
`

export type SwingChartFilters = {
  inactivePointMachines: number[]
  activeSlipCurrent?: number
  activePhases?: number
  activeAlarmPhases?: number
  activeReferenceCurve?: number
}

type OwnProps = {
  swing: Swing
  filters: SwingChartFilters
  onFiltersUpdated: (filters: Partial<SwingChartFilters>) => void
  asset: Asset
}

export const SwingChart = ({ swing, filters, onFiltersUpdated, asset }: OwnProps) => {
  useLogReactParams('SwingChart', { swing, filters, onFiltersUpdated, asset })
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language
  const { classes: chartClasses } = useChartStyles()
  const chartRef = useRef<HTMLDivElement>(null)
  const [renderer, setRenderer] = useState<SwitchChartRenderer>()
  const updateSetting = useUserProfileStore(state => state.settings.updateSetting)
  const switchChartFullWidth = useUserProfileStore(state => state.settings.settings.switchChartFullWidth)
  const pointMachineZoomLevels = useDomainConstantsStore(state => state.pointMachineZoomLevels.pointMachineZoomLevels)
  const alarmTypes = useDomainConstantsStore(state => state.alarmTypes.alarms).filter(
    a => a.type.system === MonitoringSystem.PointMachine
  )

  const slipCurrents = swing.machineSwings.map(swing => {
    return swing.swingAnalysis?.swingTrend?.lastSlipCurrentValue ?? undefined
  })

  const incompleteSwings = swing.machineSwings.filter(swing => !swing.isComplete)

  const inactivePointMachinesUpdated = (inactivePointMachines: number[]) => {
    onFiltersUpdated({
      inactivePointMachines,
    })
    if (renderer) {
      renderer.setInactivePointMachines(inactivePointMachines)
    }
  }

  const activeSlipCurrentUpdated = (activeSlipCurrent?: number) => {
    onFiltersUpdated({
      activeSlipCurrent,
    })
    if (renderer) {
      renderer.setActiveSlipCurrent(activeSlipCurrent)
    }
  }

  const activePhasesUpdated = (activePhases?: number) => {
    onFiltersUpdated({
      activePhases,
    })
    if (renderer) {
      renderer.setActivePhases(activePhases)
    }
  }

  const activeAlarmPhasesUpdated = (machineIndex: number | undefined, alarmsWithPhaseIds: Alarm[] | undefined) => {
    onFiltersUpdated({
      activeAlarmPhases: machineIndex,
    })
    if (renderer) {
      renderer.setActiveAlarmPhases(machineIndex, alarmsWithPhaseIds)
    }
  }

  const activeReferenceCurveUpdated = (activeReferenceCurve?: number) => {
    onFiltersUpdated({
      activeReferenceCurve,
    })
    if (renderer) {
      renderer.setActiveReferenceCurve(activeReferenceCurve)
    }
  }

  const toggleFullChartWidth = () => {
    updateSetting('switchChartFullWidth', !switchChartFullWidth)
  }

  useChartResizeBehaviour(renderer)

  const updateActiveAlarmPhases = () => {
    if (filters.activeAlarmPhases !== undefined) {
      const alarms = swing.machineSwings[filters.activeAlarmPhases].swingAnalysis?.alarms
      const alarmsWithPhaseIds = alarms?.filter(alarm => alarm.alarmPhaseId !== null)
      renderer?.setActiveAlarmPhases(filters.activeAlarmPhases, alarmsWithPhaseIds)
    }
  }

  useLayoutEffect(() => {
    const chart = chartRef.current
    if (chart) {
      const labels = {
        toggleForceDomain: t('switches.chart.toggleForceDomain'),
        toggleWidth: t('switches.chart.toggleWidth'),
      }

      let chartRenderer = renderer
      if (!chartRenderer) {
        const zoomLevels = pointMachineZoomLevels.find(zl =>
          swing.machineSwings.find(pm => pm.machineTypeId === zl.machineTypeId)
        )?.zoomLevels

        chartRenderer = new SwitchChartRenderer({
          chart,
          classes: {
            tooltip: chartClasses.tooltip,
            zoomButton: chartClasses.zoomButton,
          },
          zoomLevels: zoomLevels ? zoomLevels.concat(DEFAULT_ZOOM_LEVEL).reverse() : DEFAULT_ZOOM_LEVEL,
          initialFullWidth: switchChartFullWidth,
          onToggleFullChartWidth: toggleFullChartWidth,
          translations: {
            referenceCurve: t('switches.chart.referenceCurveLabel'),
            slipCurrentLabel: t('switches.chart.slipCurrentLabel'),
          },
          alarmTypes,
        })
        chartRenderer.setInactivePointMachines(filters.inactivePointMachines, false)
        chartRenderer.setActiveSlipCurrent(filters.activeSlipCurrent, false)
        chartRenderer.setActivePhases(filters.activePhases, false)
        chartRenderer.setActiveAlarmPhases(filters.activeAlarmPhases, [])
        chartRenderer.setActiveReferenceCurve(filters.activeReferenceCurve, false)
        setRenderer(chartRenderer)
      }

      chartRenderer.setLanguage(currentLanguage)
      chartRenderer.setData(swing, slipCurrents)
      chartRenderer.setLabels(labels)
      chartRenderer.render()

      updateActiveAlarmPhases()
    }
  }, [swing, chartRef, currentLanguage]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {incompleteSwings.length > 0 && (
        <Alert severity="warning">
          {t('switches.chart.incompleteSwings', {
            pointMachineNames: incompleteSwings.map(swing => swing.baneDataNameParsed).join(', '),
          })}
        </Alert>
      )}
      <StyledChartContainer>
        <StyledChart ref={chartRef} />
      </StyledChartContainer>
      <YRangeInputsProvider>
        <YRangeInputs yDomainSetter={renderer as YDomainSetter} data={swing} />
      </YRangeInputsProvider>
      <SwingLegend
        asset={asset}
        pointMachineSwings={swing.machineSwings}
        slipCurrents={slipCurrents}
        inactivePointMachines={filters.inactivePointMachines}
        activeSlipCurrent={filters.activeSlipCurrent}
        activePhases={filters.activePhases}
        activeAlarmPhases={filters.activeAlarmPhases}
        activeReferenceCurve={filters.activeReferenceCurve}
        onInactivePointMachinesUpdated={inactivePointMachinesUpdated}
        onActiveSlipCurrentUpdated={activeSlipCurrentUpdated}
        onActivePhasesUpdated={activePhasesUpdated}
        onActiveAlarmPhasesUpdated={activeAlarmPhasesUpdated}
        onActiveReferenceCurveUpdated={activeReferenceCurveUpdated}
      />
    </>
  )
}
