import type { ReactNode } from 'react'
import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'

type OwnProps = {
  children?: ReactNode
  text?: string | number
  title?: string
  hoverText?: string
}

const HoverableContainer = styled('div')`
  position: relative;
  display: inline-block;
  cursor: pointer;
  z-index: 10; /* Ensure it's above other elements */

  &:hover .hover-text {
    visibility: visible;
    opacity: 1;
  }
`

const HoverText = styled('div')`
  visibility: hidden;
  opacity: 0;
  width: 300px;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 20; /* Ensure it's above other elements */
  bottom: 100%; /* Position the tooltip above the text */
  left: 50%;
  transform: translateX(-50%);
  transition:
    opacity 0.3s,
    visibility 0.3s;

  &::after {
    content: '';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
  }
`

/**
 * Makes text or children hoverable with the hoverText showing
 * but is clickable for mobile device also.
 * Supports additional popover using title.
 * @param children
 * @param text
 * @param title
 * @param hoverText
 * @constructor
 */
export const Hoverable = ({ children, text, title, hoverText }: OwnProps) => {
  const [showHoverText, setShowHoverText] = useState(false)

  const handleClick = () => {
    setShowHoverText(!showHoverText)
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setShowHoverText(false)
      }
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (!hoverText) {
    return undefined
  }

  return (
    <HoverableContainer
      title={title}
      onClick={handleClick}
      onMouseEnter={() => window.innerWidth > 768 && setShowHoverText(true)}
      onMouseLeave={() => window.innerWidth > 768 && setShowHoverText(false)}
    >
      {children || text}
      <HoverText
        className="hover-text"
        style={{ visibility: showHoverText ? 'visible' : 'hidden', opacity: showHoverText ? 1 : 0 }}
      >
        {hoverText}
      </HoverText>
    </HoverableContainer>
  )
}
