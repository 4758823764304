import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import type { Asset } from 'src/Types/AssetTypes'
import type { Alarm } from 'src/Types/Alarm'
import type { AlarmType, DayWithAlarmStatus } from 'src/Types/AlarmType'

import { formatLocalDateTime } from 'src/Utils/format'
import { getAlarmStart, getAlarmEnd, getAlarms } from 'src/Utils/alarms'

import { assetName } from './assetName'
import { useLanguage } from 'src/Hooks/useLanguage'
import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { useLogReactParams } from 'src/Hooks/useLogReactParams'
import { CardStatusText } from 'src/Components/CardStatusText'
import { SevenDayAlarmIndicator } from 'src/Components/AssetCard/SevenDayAlarmIndicator'
import { useHideCriticality } from 'src/Hooks/useHideCriticality'

const StyledSections = styled('div')(
  props => `
  display: flex;
  flex-direction: row;
  column-gap: 3rem;

  ${props.theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`
)

const StyledSection = styled('div')`
  display: flex;
  flex-direction: column;
`

const StyledTitle = styled(StyledSection)(`
  flex: 1;
`)

const StyledDetailContainer = styled(StyledSection)`
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  gap: 10px;
  row-gap: 0;
  overflow: hidden;
  flex-wrap: wrap;
  flex: 3;
`

const StyledAssetHeader = styled(Typography)`
  display: inline;
  line-height: 1;
`

const StyledLocationHeader = styled(Typography)`
  font-size: 16px;
  display: inline-block;
`

const StyledTable = styled('table')`
  width: 100%;
  text-align: left;
  border-spacing: 0;

  th,
  td {
    padding: 0 4px;

    :first-of-type {
      padding-left: 0;
    }

    :last-of-type {
      padding-right: 0;
    }
  }
`

const StyledCardStatusAndSevenDayIndicatorContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-content: start;
  justify-content: left;
  margin-top: 10px;
  row-gap: 10px;
`

type OwnProps = {
  asset: Asset
  alarms: Alarm[]
  hideAlarmInfo: boolean
}

export const AssetCardMainAlarmDetails = ({ asset, alarms: assetAlarms, hideAlarmInfo = false }: OwnProps) => {
  useLogReactParams('AssetCardMainAlarmDetails', { asset, alarms: assetAlarms, hideAlarmInfo })

  const { t, currentLanguage } = useLanguage()
  const isMediaSM = useBreakpointDown('sm')

  const alarms = getAlarms(assetAlarms, 'Alarm')
  const warnings = getAlarms(assetAlarms, 'Warning')

  const hideCriticality = useHideCriticality()

  const lastSevenDaysAlarmStatus: DayWithAlarmStatus[] = asset.dailyStatus.map(s => ({
    status: s.statusWithAssetStatusWindows,
    date: new Date(s.fromDateTime),
  }))

  const shouldShowSevenDayIndicator = asset.isMonitored && lastSevenDaysAlarmStatus.length > 0

  const getAlarmTypeDescription = (alarmType: AlarmType) => {
    const descriptionLength = isMediaSM ? 'short' : 'full'
    return alarmType.type.localization[descriptionLength][currentLanguage]
  }

  const renderAlarms = () => {
    const isAlarms = !!alarms.length
    const alarmsToRender = Object.values(
      (isAlarms ? alarms : warnings).reduce(
        (acc, { alarm, alarmType }) => {
          const key = alarmType.type.id
          if (!acc[key]) {
            acc[key] = {
              alarm: { ...alarm },
              alarmType,
              alarmStart: getAlarmStart(alarm),
              alarmEnd: getAlarmEnd(alarm),
            }
          } else {
            acc[key].alarm.nrOfAlarms += alarm.nrOfAlarms
            acc[key].alarmStart = Math.min(acc[key].alarmStart, getAlarmStart(alarm))
            acc[key].alarmEnd = Math.max(acc[key].alarmEnd, getAlarmEnd(alarm))
          }
          return acc
        },
        {} as Record<string, { alarm: Alarm; alarmType: AlarmType; alarmStart: number; alarmEnd: number }>
      )
    )

    const sortedAlarms = alarmsToRender.sort((a, b) => b.alarmStart - a.alarmStart).slice(0, Math.min(3, alarmsToRender.length))

    if (!sortedAlarms.length || hideAlarmInfo) {
      return null
    }

    if (isMediaSM) {
      const { alarmType, alarmEnd } = sortedAlarms[0]
      const alarmDescription = getAlarmTypeDescription(alarmType)

      return (
        <>
          <div>
            <strong>{isAlarms ? t('alarms.lastAlarm') : t('alarms.lastWarning')}:</strong>
          </div>
          <div>{alarmDescription}</div>
          {alarmEnd ? <div>{formatLocalDateTime(alarmEnd)}</div> : null}
        </>
      )
    }

    return (
      <StyledTable>
        <thead>
          <tr>
            <th>{isAlarms ? t('alarms.lastAlarms') : t('alarms.lastWarnings')}</th>
            <th>{t('alarms.alarmEntry.occurrences')}</th>
            <th>{t('alarms.alarmEntry.period')}</th>
          </tr>
        </thead>
        <tbody>
          {sortedAlarms.map(({ alarm, alarmType, alarmStart, alarmEnd }, index) => {
            const alarmDescription = getAlarmTypeDescription(alarmType)
            const key = `${alarm.baneDataId}_${alarmType.type.id}_${alarmStart}_${alarmEnd}_${index}`

            return (
              <tr key={key}>
                <td>{alarmDescription}</td>
                <td>{alarm.nrOfAlarms}</td>
                <td>
                  {alarmStart && formatLocalDateTime(alarmStart)} - {alarmEnd && formatLocalDateTime(alarmEnd)}
                </td>
              </tr>
            )
          })}
        </tbody>
      </StyledTable>
    )
  }

  return (
    <StyledSections>
      <StyledTitle>
        <StyledAssetHeader variant="h6">{assetName(asset, t)}</StyledAssetHeader>
        <StyledLocationHeader variant="h5">{asset.baneDataLocationName}</StyledLocationHeader>
        <StyledCardStatusAndSevenDayIndicatorContainer>
          <CardStatusText asset={asset} hideCriticality={hideCriticality} />
          {shouldShowSevenDayIndicator && <SevenDayAlarmIndicator daysWithAlarmStatus={lastSevenDaysAlarmStatus} />}
        </StyledCardStatusAndSevenDayIndicatorContainer>
      </StyledTitle>
      <StyledDetailContainer>{renderAlarms()}</StyledDetailContainer>
    </StyledSections>
  )
}
