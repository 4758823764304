import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'

import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { StyledChip } from 'src/Components/StyledChip'
import { TableCellPeriod } from 'src/Components/TableCellPeriod'

import type { AssetStatusWindow, WindowStatus } from 'src/Types/AssetTypes'

import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { useLanguage } from 'src/Hooks/useLanguage'
import type { SubHeaderKey } from 'src/Providers/Settings/SubHeader'
import { Language } from 'src/Types/LanguageTypes'

import { StatusWindowRow } from 'src/Features/Alarms/AlarmList/StatusWindowRow'

type OwnProps = {
  windows?: AssetStatusWindow[]
  status: WindowStatus
  chipColor: string
  subHeaderKey: SubHeaderKey
  i18nAssetStatusClassification: string
  urlDescriptionKey?: string
}

const getTableRows = (windows: AssetStatusWindow[] | undefined, currentLanguage: Language) => {
  const tableRows: AssetStatusTableRow[] | undefined = windows?.map(statusWindow => {
    const cause = currentLanguage === Language.no ? statusWindow.cause.name_No : statusWindow.cause.name_En

    return {
      causeId: statusWindow.causeId,
      startTime: statusWindow.startTime,
      endTime: statusWindow.endTime,
      cause,
      descriptionUrl: statusWindow.cause.description_URL,
    }
  })
  return tableRows
}

export type AssetStatusTableRow = {
  causeId: number
  startTime: number
  endTime: number
  cause: string
  descriptionUrl?: string | null
}

export const StatusWindowTable = ({
  windows,
  status,
  chipColor,
  subHeaderKey,
  i18nAssetStatusClassification,
  urlDescriptionKey = 'statusWindow.descriptionAndActionsUrl',
}: OwnProps) => {
  const { t, currentLanguage } = useLanguage()
  const isMediaLG = useBreakpointDown('lg')
  const isMediaSM = useBreakpointDown('sm')

  const tableRows = getTableRows(windows, currentLanguage)
  const numOccurrences = tableRows?.length ?? 0

  return (
    <SubHeaderWithExpandableInfo
      title={
        <b>
          {i18nAssetStatusClassification}
          <StyledChip chipColor={chipColor} label={numOccurrences} size="small" />
        </b>
      }
      hideText={t('alarms.hide', {
        classification: i18nAssetStatusClassification?.toLowerCase(),
      })}
      isDefaultExpanded
      subHeaderKey={subHeaderKey}
    >
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {isMediaSM ? (
                <TableCellPeriod padding="checkbox">
                  <b>{t('alarms.alarmEntry.period')}</b>
                </TableCellPeriod>
              ) : (
                <>
                  <TableCell padding="checkbox">
                    <b>{t('alarms.alarmEntry.from')}</b>
                  </TableCell>
                  <TableCell padding="checkbox">
                    <b>{t('alarms.alarmEntry.to')}</b>
                  </TableCell>
                </>
              )}
              <TableCell padding="checkbox">
                <b>{t('manualAssetStatus.cause')}</b>
              </TableCell>
              <TableCell padding="checkbox" title={t(`${urlDescriptionKey}.long`)}>
                <b>{t(`${urlDescriptionKey}.${isMediaLG ? 'short' : 'medium'}`)}</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows?.map(row => (
              <StatusWindowRow
                key={`${status}-${row.causeId}-${row.startTime}-${row.endTime}`}
                startTime={row.startTime}
                endTime={row.endTime}
                cause={row.cause}
                status={status}
                url={row.descriptionUrl}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </SubHeaderWithExpandableInfo>
  )
}
