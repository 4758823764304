import { useMemo, useState } from 'react'

import { type WorkOrder } from 'src/Types/WorkOrderTypes'
import type { Order, TableHead } from 'src/Types/TableTypes'

import { Table } from 'src/Components/UIComponents'
import { WorkOrdersTableRow } from './WorkOrdersTableRow'

import { useLanguage } from 'src/Hooks/useLanguage'

import { sortArrayOfObjectsByKey } from 'src/Utils/array'
import type { TFunction } from 'i18next'

const getWorkOrderTableHead = (
  t: TFunction<string, undefined>,
  translationKeySuffix: string,
  keyOfWorkOrderIfDifferent?: keyof WorkOrder
) => {
  const keyOfWorkOrder = keyOfWorkOrderIfDifferent ?? translationKeySuffix

  if (!(keyOfWorkOrder as keyof WorkOrder)) {
    throw Error(`This key '${keyOfWorkOrder}' is not supported for sorting!`)
  }

  return {
    value: t(`workOrders.${translationKeySuffix}`),
    id: keyOfWorkOrderIfDifferent ?? translationKeySuffix,
  } as TableHead
}

const getTableHeads = (forCompletedWorkOrders: boolean, t: TFunction<string, undefined>) => {
  const commonHeadsToTheLeft = [
    getWorkOrderTableHead(t, 'description'),
    getWorkOrderTableHead(t, 'workType'),
    getWorkOrderTableHead(t, 'status'),
    getWorkOrderTableHead(t, 'discipline'),
  ]

  const conditionalHeadsInTheMiddle = forCompletedWorkOrders
    ? [getWorkOrderTableHead(t, 'timeOfCompletion')]
    : [getWorkOrderTableHead(t, 'plannedTimeOfExecution')]

  const commonHeadsToTheRight = [getWorkOrderTableHead(t, 'workOrderId'), getWorkOrderTableHead(t, 'assignedTo', 'supervisor')]

  return [...commonHeadsToTheLeft, ...conditionalHeadsInTheMiddle, ...commonHeadsToTheRight]
}

type OwnProps = {
  workOrders: WorkOrder[]
  label: string
  isComplete?: boolean
}

export const WorkOrdersTable = ({ workOrders, label, isComplete = false }: OwnProps) => {
  const { t } = useLanguage()

  const defaultSortOrderForWorkOrderId = 'desc' as Order
  const defaultSortOrderForOtherColumns = 'asc' as Order

  const [orderBy, setOrderBy] = useState<keyof WorkOrder>('workOrderId')
  const [order, setOrder] = useState<Order>(defaultSortOrderForWorkOrderId)

  const sortedWorkOrders = useMemo(() => sortArrayOfObjectsByKey(workOrders, orderBy, order), [workOrders, orderBy, order])

  const handleSort = (key: string) => {
    if (key !== orderBy) {
      setOrderBy(key as keyof WorkOrder)
      setOrder(defaultSortOrderForOtherColumns)
    } else {
      setOrder(order === 'asc' ? 'desc' : 'asc')
    }
  }

  return (
    <Table
      label={label}
      tableHeads={getTableHeads(isComplete, t)}
      noMatchesTextKey="workOrders.noFilteredMatches"
      order={order}
      orderBy={orderBy}
      handleSort={handleSort}
      usePagination
      canSortColumns
    >
      {sortedWorkOrders.map(wo => (
        <WorkOrdersTableRow key={wo.workOrderId} workOrder={wo} isComplete={isComplete} />
      ))}
    </Table>
  )
}
