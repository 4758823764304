import type { Alarm } from 'src/Types/Alarm'
import type { AggregatedSwitchHeatAlarm } from 'src/Types/AggregatedSwitchHeatAlarm'
import {
  alarmHasAdditionalInfoAndTypeToShow,
  getAdditionalInfoTypeByAlarm,
  isSwitchAlarmWithInfoToShow,
  isSwitchHeatAlarmWithInfoToShow,
} from 'src/Utils/alarms'
import { CellEntry, CellEntryIfAny } from 'src/Components/CellEntry'
import TableCell from '@mui/material/TableCell'
import { useLanguage } from 'src/Hooks/useLanguage'
import { Hoverable } from 'src/Components/Hoverable'
import { Language } from 'src/Types/LanguageTypes'
import { styled } from '@mui/material/styles'
import type { AlarmDefinition } from 'src/Types/AlarmType'
import type { TFunction } from 'i18next'
import { SlashSeparated } from 'src/Components/SlashSeparated'
import themeColors from 'src/theme'

const PadTopAndBottom = styled('div')`
  padding: 1em 0;
`

const LineSeparator = styled('div')`
  width: 100%;
  height: 1px;
  background-color: ${themeColors.sectionAitSeparator};
  margin: 0.5em 0;
`

const getHoverTextAboutAdditionalInfoType = (alarm: Alarm, language: Language, t: TFunction<string, undefined>) => {
  const additionalInfoType = getAdditionalInfoTypeByAlarm(alarm)
  const description =
    language === Language.no
      ? additionalInfoType?.description_NO ?? additionalInfoType?.description_EN
      : additionalInfoType?.description_EN ?? additionalInfoType?.description_NO

  return description ?? t('alarms.additionalInfoNotSpecified')
}

const objectDecorationIfClassified = (t: TFunction<string, undefined>, classification?: AlarmDefinition<'Alarm' | 'Warning'>) =>
  classification ? t(`alarms.objectDecoratedAdditionalInfo.${classification.id.toLowerCase()}`) : undefined

type OwnProps = {
  alarm: Alarm
  classification?: AlarmDefinition<'Alarm' | 'Warning'>
}

export const DetailsCell = ({ alarm, classification }: OwnProps) => {
  const { t, currentLanguage } = useLanguage()

  const willShowSwitchHeatRelatedInfo = isSwitchHeatAlarmWithInfoToShow(alarm)
  const willShowBaneDataName = isSwitchAlarmWithInfoToShow(alarm)

  const willDecorateObject = willShowBaneDataName || willShowSwitchHeatRelatedInfo

  const willShowAdditionalInfoAndType = alarmHasAdditionalInfoAndTypeToShow(alarm)

  const aggrAlarm = alarm as AggregatedSwitchHeatAlarm

  return (
    <TableCell padding="checkbox">
      {(willDecorateObject || willShowAdditionalInfoAndType) && (
        <PadTopAndBottom>
          <SlashSeparated separator=<LineSeparator />>
            {willDecorateObject && (
              <Hoverable hoverText={objectDecorationIfClassified(t, classification)}>
                {willShowBaneDataName && CellEntry(alarm.baneDataName ?? 'alarm.baneDataName')}
                {willShowSwitchHeatRelatedInfo && (
                  <>
                    {CellEntryIfAny(aggrAlarm.switchName)}
                    {!aggrAlarm.switchName && CellEntryIfAny(aggrAlarm.switchHeatElementName)}
                  </>
                )}
              </Hoverable>
            )}
            {willShowAdditionalInfoAndType && (
              <Hoverable hoverText={getHoverTextAboutAdditionalInfoType(alarm, currentLanguage, t)}>
                {CellEntry(aggrAlarm.additionalInfo)}
              </Hoverable>
            )}
          </SlashSeparated>
        </PadTopAndBottom>
      )}
    </TableCell>
  )
}
