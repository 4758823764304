import type { ReactNode } from 'react'
import React from 'react'

type OwnProps = {
  children?: ReactNode
  separator?: string | ReactNode
}

export const SlashSeparated = ({ children, separator = ' / ' }: OwnProps) => {
  const childrenArray = React.Children.toArray(children)

  return (
    <>
      {childrenArray.map((child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={index}>
          {child}
          {index < childrenArray.length - 1 && <span>{separator}</span>}
        </span>
      ))}
    </>
  )
}
