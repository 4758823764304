import React, { useState, useMemo } from 'react'
import type { YRangeInputsState } from 'src/Components/Chart/YRangeInputs/YRangeInputsContext'
import YRangeInputsContext from 'src/Components/Chart/YRangeInputs/YRangeInputsContext'

type OwnProps = {
  children: React.ReactNode
}

export const YRangeInputsProvider = ({ children }: OwnProps) => {
  const [state, setState] = useState<YRangeInputsState>({ activated: false, minY: null, maxY: null })

  const value = useMemo(() => ({ state, setState }), [state])

  return <YRangeInputsContext.Provider value={value}>{children}</YRangeInputsContext.Provider>
}
