import { useState } from 'react'

import { TableRow, TableCell, IconButton, styled } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import type { WorkOrder } from 'src/Types/WorkOrderTypes'

import { WorkOrdersTableCollapse } from './WorkOrdersTableCollapse'

import { formatLocalDateTime } from 'src/Utils/format'

import themeColors from 'src/theme'

export const ClickableTableRow = styled(TableRow)`
  &:hover {
    cursor: pointer;
    background-color: rgb(235, 235, 249); // ${themeColors.sectionBackground};
  }

  & > * {
    border-bottom: unset;
  }
`
const getValueDependingOnContext = (isComplete: boolean, workOrder: WorkOrder) => {
  if (isComplete) {
    return workOrder.timeOfCompletion ? formatLocalDateTime(new Date(workOrder.timeOfCompletion), true) : ''
  }

  return workOrder.plannedTimeOfExecution ? formatLocalDateTime(new Date(workOrder.plannedTimeOfExecution), true) : ''
}

export const WorkOrdersTableRow = (props: { workOrder: WorkOrder; isComplete: boolean }) => {
  const { workOrder, isComplete } = props
  const [open, setOpen] = useState(false)

  return (
    <>
      <ClickableTableRow
        key={workOrder.workOrderId}
        sx={{ backgroundColor: open ? themeColors.sectionAlternateRow : 'unset' }}
        onClick={() => setOpen(!open)}
      >
        <TableCell sx={{ width: '24px', padding: 0 }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell sx={{ minWidth: '200px' }}>{workOrder.description}</TableCell>

        <TableCell sx={{ width: '35px' }}>{workOrder.workType}</TableCell>

        <TableCell sx={{ width: '60px' }}>{workOrder.status}</TableCell>

        <TableCell sx={{ width: '70px' }}>{workOrder.discipline ?? ''}</TableCell>

        <TableCell sx={{ width: '75px' }}>{getValueDependingOnContext(isComplete, workOrder)}</TableCell>
        <TableCell sx={{ width: '70px' }}>{workOrder.id}</TableCell>

        <TableCell sx={{ width: '120px', minWidth: '120px' }}>{workOrder.supervisor}</TableCell>
      </ClickableTableRow>

      <WorkOrdersTableCollapse isComplete={isComplete} open={open} workOrder={workOrder} />
    </>
  )
}
