const ConditionalCellEntry = (value?: string | number | null, title?: string, onlyIfValueOrTitle: boolean = false) => {
  if (onlyIfValueOrTitle && !(value || title)) {
    return undefined
  }
  return <div title={title}>{value}</div>
}

export const CellEntryIfAny = (value?: string | number | null, title?: string) => ConditionalCellEntry(value, title, true)

export const CellEntry = (value?: string | number | null, title?: string) => ConditionalCellEntry(value, title, false)
