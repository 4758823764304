import type React from 'react'
import { styled } from '@mui/material/styles'

export const styledWithBreakpoints = (
  Component: React.ComponentType<any>,
  defaultMinWidth?: string,
  defaultMinWidthSm?: string
) =>
  styled(Component)(
    ({ minWidth = defaultMinWidth, minWidthSm = defaultMinWidthSm, theme }) => `
  min-width: ${minWidth !== undefined ? minWidth : 'auto'};
  ${theme.breakpoints.down('sm')} {
    min-width: ${minWidthSm !== undefined ? minWidthSm : 'auto'};
  }
`
  )
