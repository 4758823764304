import type React from 'react'
import { createContext, useContext } from 'react'

export type YRangeInputsState = { activated: boolean; minY: number | null; maxY: number | null }

type YRangeInputsContextProps = {
  state: YRangeInputsState
  setState: React.Dispatch<React.SetStateAction<YRangeInputsState>>
}

const YRangeInputsContext = createContext<YRangeInputsContextProps | undefined>(undefined)

export const useYRangeInputsContext = () => {
  const context = useContext(YRangeInputsContext)
  if (!context) {
    throw new Error('useYRangeInputsContext must be used within a YRangeInputsProvider')
  }
  return context
}

export default YRangeInputsContext
