import { styled } from '@mui/material/styles'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'

import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { StyledChip } from 'src/Components/StyledChip'
import { TableCellPeriod } from 'src/Components/TableCellPeriod'

import type { Alarm } from 'src/Types/Alarm'
import type { WindowStatus } from 'src/Types/AssetTypes'

import type { AlarmAndType, AlarmTypeOccurrences } from 'src/Utils/alarms'
import { alarmTableHasDetailsColumn, getAlarmStart } from 'src/Utils/alarms'
import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { useLanguage } from 'src/Hooks/useLanguage'
import type { SubHeaderKey } from 'src/Providers/Settings/SubHeader'
import { styledWithBreakpoints } from 'src/Utils/styled'

import { AlarmEntry } from './AlarmEntry'

const TableCellAlarmType = styledWithBreakpoints(TableCell, '130px', '120px')
const TableCellUnderlined = styled(TableCell)`
  text-decoration: underline;
  cursor: pointer;
`

const uniqueAlarmId = (alarm: Alarm, i: number) => {
  return `${alarm.alarmTypeId}_${getAlarmStart(alarm)}_${alarm.baneDataId}_${i}`
}

type OwnProps = {
  alarms: AlarmAndType[]
  occurrences: AlarmTypeOccurrences
  chipColor: string
  showAlarmsInAssetStatusWindows: boolean
  relevantWindowStatuses?: WindowStatus | 'Both'
  expandFirstAlarmChart?: boolean
  subHeaderKey: SubHeaderKey
}

export const AlarmTable = ({
  alarms,
  occurrences,
  chipColor,
  showAlarmsInAssetStatusWindows,
  relevantWindowStatuses,
  expandFirstAlarmChart = false,
  subHeaderKey,
}: OwnProps) => {
  const { t, currentLanguage } = useLanguage()
  const isMediaSM = useBreakpointDown('sm')

  const alarmClassification = alarms.length ? alarms[0].alarmType.classification.localization.full[currentLanguage] : undefined
  const numOccurrences = showAlarmsInAssetStatusWindows ? occurrences.total : occurrences.real

  const tableHasDetailsColumn = alarmTableHasDetailsColumn(alarms)

  return (
    <SubHeaderWithExpandableInfo
      title={
        <b>
          {alarmClassification}
          <StyledChip chipColor={chipColor} label={numOccurrences} size="small" />
        </b>
      }
      hideText={t('alarms.hide', {
        classification: alarmClassification?.toLowerCase(),
      })}
      isDefaultExpanded
      subHeaderKey={subHeaderKey}
    >
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCellAlarmType padding="checkbox">
                <b>{t('alarms.alarmEntry.type')}</b>
              </TableCellAlarmType>
              <TableCell padding="checkbox">
                <b>{t(`alarms.alarmEntry.number.${isMediaSM ? 'short' : 'long'}`)}</b>
              </TableCell>
              {isMediaSM ? (
                <TableCellPeriod padding="checkbox">
                  <b>{t('alarms.alarmEntry.period')}</b>
                </TableCellPeriod>
              ) : (
                <>
                  <TableCell padding="checkbox">
                    <b>{t('alarms.alarmEntry.from')}</b>
                  </TableCell>
                  <TableCell padding="checkbox">
                    <b>{t('alarms.alarmEntry.to')}</b>
                  </TableCell>
                </>
              )}
              {tableHasDetailsColumn && (
                <TableCellUnderlined padding="checkbox" title={t('alarms.detailsColumnHeaderHoverText')}>
                  <b>{t('general.details')}</b>
                </TableCellUnderlined>
              )}
              <TableCell padding="checkbox" title={t('alarms.alarmDescriptionsUrl.long')} align="center">
                <>
                  <b>{t('alarms.alarmDescriptionsUrl.mediumStart')}</b>
                  <br />
                  <b>{t('alarms.alarmDescriptionsUrl.mediumEnd')}</b>
                </>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alarms.map(({ alarm }, i) => (
              <AlarmEntry
                alarm={alarm}
                showAlarmsInAssetStatusWindows={showAlarmsInAssetStatusWindows}
                enableDetailsCell={tableHasDetailsColumn}
                activeWindowStatuses={relevantWindowStatuses}
                expandAlarmChart={expandFirstAlarmChart && i === 0}
                key={uniqueAlarmId(alarm, i)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </SubHeaderWithExpandableInfo>
  )
}
