export const normalizeString = (str?: string | null) => {
  if (!str) {
    return str
  }
  return unliReplace('  ', ' ', unliReplace('..', ' ', str.trim().toLowerCase()))
}

export const unliReplace = (searchValue: string, replaceValue: string, str?: string | null) => {
  if (!str || replaceValue.includes(searchValue)) {
    return str
  }
  let result = str
  while (result.includes(searchValue)) {
    result = result.replace(searchValue, replaceValue)
  }
  return result
}

export const camelCase = (toBeCamelCased: string) => {
  return toBeCamelCased
    .replace(/[_\s\-.,!?]+(.)?/g, (match, chr) => (chr ? chr.toUpperCase() : ''))
    .replace(/^[A-Z]/, match => match.toLowerCase())
}
