import type { SystemStatusSeverity, AffectedSystem, SystemStatusAlert } from 'src/Types/SystemStatusAlert'

import { getSystemStatusAlerts } from 'src/Providers/SystemStatusAlerts'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'
import {
  alertStubForError,
  alertStubForInfoOnlyOnAlarms,
  alertStubForInfoOnlyOnSvv,
  alertStubForErrorNotOnlyOnTrackCircuit,
} from 'test/stubs/systemStatusAlerts'

type UseSystemStatusAlertsParams = {
  severity?: SystemStatusSeverity
  includeStubbedAlerts?: boolean
  includeAzureAlerts?: boolean
  includeTeamsAlerts?: boolean
  affectedSystems?: AffectedSystem[]
}

/**
 * To be included among possible real alerts if specified so
 * (i.e. if having includeStubbedAlerts = true)
 */
const defaultArrayOfStubbedAlerts = [
  alertStubForError,
  alertStubForInfoOnlyOnSvv,
  alertStubForErrorNotOnlyOnTrackCircuit,
  alertStubForInfoOnlyOnAlarms,
]

const REFETCH_INTERVAL = 5 * 60 * 1000

/**
 * Returns the current system status alerts, and lets you include
 * stubbed ones if you want. In case you do, however, be sure they have 'Active'
 * SystemStatusState (or else not realistic / consistent with current usage)
 *
 * @param params UseSystemStatusAlertsParams in which in practice only the
 * includeStubbedAlerts and the route's affected systems are specified
 * (in the call)
 */
export const useSystemStatusAlerts = (params: UseSystemStatusAlertsParams) => {
  const query = useQueryWithErrorHandling(
    ['systemStatusAlerts', params],
    () => getSystemStatusAlerts({ affectedSystems: params.affectedSystems }),
    {
      refetchInterval: REFETCH_INTERVAL,
    },
    'general.systemStatusAlert.fetchError'
  )

  let systemStatusAlerts: SystemStatusAlert[] = query.data ?? []

  if (params.includeStubbedAlerts) {
    defaultArrayOfStubbedAlerts?.forEach(stubbedAlert => {
      systemStatusAlerts.push(stubbedAlert)
    })
    systemStatusAlerts = [...new Set(systemStatusAlerts)]
  }

  return {
    ...query,
    systemStatusAlerts,
  }
}
