import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { CommaDecimalNumberInput } from 'src/Components/UIComponents/Input/CommaDecimalNumberInput'
import { useYRangeInputsContext } from 'src/Components/Chart/YRangeInputs/YRangeInputsContext'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useTranslation } from 'react-i18next'
import type { YDomainSetter } from 'src/Components/Chart/YRangeInputs/YRangeInputsInterfaces'

const YRangeInputsContainer = styled('div')(
  props => `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 32px;

  ${props.theme.breakpoints.down('md')} {
    flex-direction: row;
    align-items: flex-start;
  }
`
)

const StyledDecimalInput = styled(CommaDecimalNumberInput)(
  props => `
  margin: 0 1em;
  max-width: 6em;

  ${props.theme.breakpoints.down('md')} {
    margin: 0 0.42em;
  }
`
)

const StyledDash = styled('span')`
  align-self: flex-start;
  text-align: center;
  margin-top: 0.75em;
  font-size: x-large;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-top: 0.8em;
`

type OwnProps = {
  yDomainSetter?: YDomainSetter
  className?: string
  data: any
}

export const YRangeInputs = ({ yDomainSetter, className, data }: OwnProps) => {
  const { t } = useTranslation()
  const { state, setState } = useYRangeInputsContext()

  const handleMinYChange = (value: number | null) => {
    setState(prevState => ({ ...prevState, minY: value }))
    if (state.activated) {
      yDomainSetter?.setYDomain(value, state.maxY)
    }
  }

  const handleMaxYChange = (value: number | null) => {
    setState(prevState => ({ ...prevState, maxY: value }))
    if (state.activated) {
      yDomainSetter?.setYDomain(state.minY, value)
    }
  }

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const activated = event.target.checked
    setState(prevState => ({ ...prevState, activated }))
    if (activated) {
      if (state.minY !== null || state.maxY !== null) {
        yDomainSetter?.setYDomain(state.minY, state.maxY)
      }
    } else {
      yDomainSetter?.setYDomain(null, null)
    }
  }

  useEffect(() => {
    if (state.activated) {
      yDomainSetter?.setYDomain(state.minY, state.maxY)
    }
  }, [data, state.activated, state.minY, state.maxY, yDomainSetter])

  return (
    <YRangeInputsContainer className={className}>
      <StyledDecimalInput
        id="minY"
        value={state.minY}
        label={t('charts.yRangeInputs.minValue')}
        onValueChange={handleMinYChange}
      />
      <StyledDash>-</StyledDash>
      <StyledDecimalInput
        id="maxY"
        value={state.maxY}
        label={t('charts.yRangeInputs.maxValue')}
        onValueChange={handleMaxYChange}
      />
      <StyledFormControlLabel
        control={
          <Switch
            checked={state.activated}
            onChange={handleToggleChange}
            inputProps={{ 'aria-label': t('charts.yRangeInputs.activate') }}
          />
        }
        label={t('charts.yRangeInputs.activate')}
      />
    </YRangeInputsContainer>
  )
}
