import React, { useState, useEffect } from 'react'
import type { TextFieldProps } from '@mui/material/TextField'
import { TextField } from '@mui/material'

type OwnProps = {
  onValueChange: (value: number | null) => void
  value: number | null
  variant?: 'filled' | 'outlined' | 'standard'
  label?: string
} & Omit<TextFieldProps, 'onChange' | 'value'>

export const CommaDecimalNumberInput = ({ onValueChange, value, variant = 'standard', label, ...props }: OwnProps) => {
  const [displayValue, setDisplayValue] = useState<string>('')

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setDisplayValue(value.toString().replace('.', ','))
    } else {
      setDisplayValue('')
    }
  }, [value])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value

    // Allow only numbers, commas, periods, and the minus sign
    const validInput = /^-?[0-9]*[.,]?[0-9]*$/.test(inputValue)

    if (validInput) {
      if (inputValue === '-' || inputValue.endsWith(',')) {
        setDisplayValue(inputValue)
      } else {
        inputValue = inputValue.replace(',', '.')
        const numericValue = inputValue ? parseFloat(inputValue) : null
        if (!Number.isNaN(numericValue)) {
          onValueChange(numericValue)
        }
        setDisplayValue(inputValue.replace('.', ','))
      }
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const cursorPosition = event.currentTarget.selectionStart
    if (event.key === 'Backspace' && cursorPosition !== null && cursorPosition > 0) {
      if (displayValue[cursorPosition - 1] === ',') {
        event.preventDefault()
        setDisplayValue(displayValue.slice(0, cursorPosition - 1) + displayValue.slice(cursorPosition))
        event.currentTarget.setSelectionRange(cursorPosition - 1, cursorPosition - 1)
      }
    }
  }

  const handleBlur = () => {
    if (displayValue.endsWith(',')) {
      setDisplayValue(displayValue.slice(0, -1))
    }
  }

  return (
    <TextField
      label={label}
      variant={variant}
      placeholder={label}
      value={displayValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      autoComplete="off"
      inputProps={{
        inputMode: 'decimal',
        pattern: '-?[0-9]*[.,]?[0-9]*', // Allow negative numbers
      }}
      {...props}
    />
  )
}
