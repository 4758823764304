import type { SystemStatusAlert } from 'src/Types/SystemStatusAlert'

export const alertStubForInfoOnlyOnSvv = {
  source: 'Devops',
  state: 'Active',
  title: 'TESTING Downtime for info-melding uten tidsperiode vist, vist kun på SVV-siden',
  // eslint-disable-next-line max-len
  text: '[HidePeriod,&nbsp;HideFromOtherPages]&nbsp;Dette er en dev-only og SVV-siden-only test av Downtime-sak for info-melding (vises ikke lenger fra og med fredag kl. 15) ',
  severity: 'Info',
  affectedSystems: ['Sporvekselvarme'],
  fromUtc: 1705964400000,
  toUtc: 1706277600000,
  isCollapsible: true,
  isDefaultExpanded: true,
} as SystemStatusAlert

export const alertStubForErrorNotOnlyOnTrackCircuit = {
  source: 'Devops',
  state: 'Active',
  title: 'TESTING Downtime for error-melding uten tidsperiode vist, men ikke vist kun på Sporfelt-siden',
  // eslint-disable-next-line max-len
  text: '[HidePeriod] Dette er en stubbet Sporfelt-relatert test av Downtime-sak for info-melding (vises ikke lenger fra og med fredag kl. 15) ',
  severity: 'Error',
  affectedSystems: ['Sporfelt'],
  fromUtc: 1705964400000,
  toUtc: 1706277600000,
  isCollapsible: true,
  isDefaultExpanded: true,
} as SystemStatusAlert

export const alertStubForInfoOnlyOnAlarms = {
  source: 'Devops',
  state: 'Active',
  title: 'TESTING Downtime for info-melding uten tidsperiode vist, vist kun på Alarms-siden',
  // eslint-disable-next-line max-len
  text: '[HidePeriod,&nbsp;HideFromOtherPages]&nbsp;Dette er en dev-only og Alarms-siden-only test av Downtime-sak for info-melding (vises ikke lenger fra og med fredag kl. 15) ',
  severity: 'Info',
  affectedSystems: ['Alarms'],
  fromUtc: 1705964400000,
  toUtc: 1706277600000,
  isCollapsible: true,
  isDefaultExpanded: true,
} as SystemStatusAlert

export const alertStubForInfo = {
  source: 'Devops',
  state: 'Active',
  title: 'TESTING Downtime for info-melding',
  // eslint-disable-next-line max-len
  text: 'Lite info her altså',
  severity: 'Info',
  affectedSystems: ['Sporvekselvarme'],
  fromUtc: 1705964400000,
  toUtc: 1706277600000,
  isCollapsible: true,
  isDefaultExpanded: true,
} as SystemStatusAlert

export const alertStubForInfoSecondVariant = {
  source: 'Devops',
  state: 'Active',
  title: 'TESTING Downtime for info-melding (annen variant)',
  // eslint-disable-next-line max-len
  text: 'Her er det ekstremt mye info som, så den blir bredere (Imperdiet enim habit 350 asse. Suscipit justo massa. Aptent et maximus 400 lacus. Fermentum amet nullam tellus. Convallis 450 varius ex venenatis tincidunt)',
  severity: 'Info',
  affectedSystems: ['Sporvekselvarme'],
  fromUtc: 1705964400000,
  toUtc: 1706277600000,
  isCollapsible: true,
  isDefaultExpanded: true,
} as SystemStatusAlert

export const alertStubForError = {
  source: 'Devops',
  state: 'Active',
  title: 'TESTING En ekstra, men med error-severity og gjelder alle systemer/Alarms',
  // eslint-disable-next-line max-len
  text: 'NB: Ikke oppdater denne saken! Denne er relatert til 19494 (ikke oppdater den heller!) Dette er for dev-only testing (ment kun i dev). Går også ut på dato fredag kl. 15.&nbsp; ',
  severity: 'Error',
  affectedSystems: ['Alarms'],
  fromUtc: 1706050800000,
  toUtc: 1706277600000,
  isCollapsible: true,
  isDefaultExpanded: true,
} as SystemStatusAlert

export const alertStubForErrorSecondVariant = {
  source: 'Devops',
  state: 'Active',
  title: 'TESTING En ekstra (2), men med error-severity og gjelder alle systemer/Alarms',
  // eslint-disable-next-line max-len
  text: 'NB: Ikke oppdater denne saken! Denne er relatert til 19494 (ikke oppdater den heller!) Dette er for dev-only testing (ment kun i dev). Går også ut på dato fredag kl. 15.&nbsp; ',
  severity: 'Error',
  affectedSystems: ['Alarms'],
  fromUtc: 1706050800000,
  toUtc: 1706277600000,
  isCollapsible: true,
  isDefaultExpanded: true,
} as SystemStatusAlert

export const alertStubForWarning = {
  source: 'Devops',
  state: 'Active',
  title: 'TESTING En ekstra, men med warning-severity og gjelder alle systemer/Alarms',
  // eslint-disable-next-line max-len
  text: 'NB: Ikke oppdater denne saken! Denne er relatert til 19494 (ikke oppdater den heller!) Dette er for dev-only testing (ment kun i dev). Går også ut på dato fredag kl. 15.&nbsp; ',
  severity: 'Warning',
  affectedSystems: ['Alarms'],
  fromUtc: 1706050800000,
  toUtc: 1706277600000,
  isCollapsible: true,
  isDefaultExpanded: true,
} as SystemStatusAlert

export const alertStubForWarningSecondVariant = {
  source: 'Devops',
  state: 'Active',
  title: 'TESTING En ekstra (3), men med warning-severity og gjelder alle systemer/Alarms',
  // eslint-disable-next-line max-len
  text: 'NB: Ikke oppdater denne saken! Denne er relatert til 19494 (ikke oppdater den heller!) Dette er for dev-only testing (ment kun i dev). Går også ut på dato fredag kl. 15.&nbsp; ',
  severity: 'Warning',
  affectedSystems: ['Alarms'],
  fromUtc: 1706050800000,
  toUtc: 1706277600000,
  isCollapsible: true,
  isDefaultExpanded: true,
} as SystemStatusAlert
