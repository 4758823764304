import * as d3 from 'd3'
import * as d3zoom from 'd3-zoom'
import { nanoid } from 'nanoid'
import { format } from 'date-fns/format'

import { resetZoomButton, zoomInButton, zoomOutButton } from 'src/Components/Chart/chartStyles'

import type {
  SimpleSelection,
  LinearTimeScale,
  LinearNumberScale,
  LogNumberScale,
  Extent,
  ChartLabels,
  Rect,
  SVGGSelection,
  SVGRectSelection,
  NumberInterval,
  ChartEntry,
  ChartFocusUpdatedEventHandler,
} from 'src/Types/ChartTypes'
import { Language } from 'src/Types/LanguageTypes'
import type { SensorHistory } from 'src/Types/SensorTwin'

import { clamp } from 'src/Utils/number'
import { createTimeMultiFormat } from 'src/Utils/createTimeMultiFormat'
import { getD3Locale } from 'src/Language/d3Locale'
import {
  getTooltipPosition,
  tooltipArrowBoxWidth,
  tooltipArrowPolygon,
  getTooltipConfig,
  getChartLineColorByIndex,
} from 'src/Utils/chart'
import themeColors from 'src/theme'
import { enablePageScrollForChartRenderer } from 'src/Components/Chart/enablePageScrollForChartRenderer'

export type TooltipEntry = {
  value: number
  label: string
  index: number
}

export type ChartProps = {
  chart: HTMLDivElement
  timeline: NumberInterval
  classes: {
    tooltip: string
    tooltipArrow: string
    zoomButton: string
  }
  config: {
    chartMargin: Rect
    maxZoomMagicConstant: number // This magic number results in a nice max zoom resolution
    linearYScale: boolean
    useCanvas: boolean
    formatYTick: (value: number) => string
    renderValueCurveSVG?: (xScale: LinearTimeScale, yScale: LinearNumberScale | LogNumberScale) => d3.Line<[number, number]>
    renderValueCurveCanvas?: (
      data: ChartEntry[],
      xScale: LinearTimeScale,
      yScale: LinearNumberScale | LogNumberScale,
      context: CanvasRenderingContext2D
    ) => string | null
  }
  onZoomUpdated?: ChartFocusUpdatedEventHandler
}

enum ElementClass {
  canvas = 'canvas',
  zeroLine = 'zeroLine',
  dataCurve = 'dataCurve',
  tooltipPoint = 'tooltip-point',
  tooltipArrow = 'tooltip-arrow',
  tooltipPoints = 'tooltip-points',
  zoomInButton = 'zoom-in-button',
  zoomOutButton = 'zoom-out-button',
  resetZoomButton = 'reset-zoom-button',
}
const classSelector = (key: ElementClass) => `.${key}`

const CHART_HEIGHT = 400
const ZOOM_BUTTON_MARGIN = 10
const ZOOM_BUTTON_SPACING = 35
const MIN_ZOOM = 1
const X_PIXELS_PER_TICK = 90
const TOOLTIP_DATE_FORMAT = 'dd.MM.yyyy HH:mm:ss'
const TOOLTIP_POINT_RADIUS = 4
const CANVAS_PIXEL_RATIO = 1

const cullData = (data: ChartEntry[], left: number, right: number) => {
  const firstIndex = data.findIndex((o, i) => data[i + 1] && data[i + 1][0] >= left)

  const lastIndex = data
    .slice()
    .reverse()
    .findIndex((o, i) => data[i] && data[i][0] >= right)

  // If not found, use the rest of the array
  const lastEntry = lastIndex === -1 ? data.length : lastIndex + 1

  if (firstIndex !== -1) {
    return data.slice(firstIndex, lastEntry)
  }

  return []
}

const renderValueCurveSVG = (xScale: LinearTimeScale, yScale: LinearNumberScale) =>
  d3
    .line()
    .curve(d3.curveStepAfter)
    .x(d => xScale(d[0])!)
    .y(d => yScale(d[1])!)

const renderValueCurveCanvas = (
  data: ChartEntry[],
  xScale: LinearTimeScale,
  yScale: LinearNumberScale | LogNumberScale,
  context: CanvasRenderingContext2D
) => {
  const mapped = data.flatMap<[number, number]>(d => {
    const v = d[1]
    return v !== null ? [[d[0], v]] : [[d[0], -Infinity]]
  })

  return d3
    .line()
    .curve(d3.curveStepAfter)
    .x(d => xScale(d[0])!)
    .y(d => yScale(d[1])!)
    .defined(d => d[1] !== -Infinity)
    .context(context)(mapped)
}

export class ChartRenderer {
  chart: HTMLDivElement

  config: ChartProps['config']

  chartWidth: number

  timeline: NumberInterval

  dataCurves: ChartEntry[][] = []

  inactiveSeries: number[] = []

  svgContainer: SimpleSelection<SVGSVGElement>

  canvasProps?: {
    canvas: SimpleSelection<HTMLCanvasElement>

    canvasContext: CanvasRenderingContext2D

    offScreenCanvas: HTMLCanvasElement

    offScreenContext: CanvasRenderingContext2D
  }

  xScale: LinearTimeScale

  yLeftScale: LinearNumberScale | LogNumberScale

  currentXScale: LinearTimeScale

  currentYScale: LinearNumberScale | LogNumberScale

  xAxis: SVGGSelection

  yAxis: SVGGSelection

  yAxisGrid: SVGGSelection

  zoom: d3.ZoomBehavior<SVGSVGElement, unknown>

  onZoomUpdated?: ChartFocusUpdatedEventHandler

  showZoomButtons = true

  clipUrl: string

  svgClipPath: SVGRectSelection

  mouseOverlay: SVGRectSelection

  tooltip: SimpleSelection<HTMLDivElement>

  tooltipArrow: SimpleSelection<SVGSVGElement>

  tooltipPoints: SVGGSelection

  multiTimeFormat?: ReturnType<typeof createTimeMultiFormat>

  labels: ChartLabels = {}

  currentLanguage: Language = Language.no

  constructor({ chart, timeline, classes, config, onZoomUpdated }: ChartProps) {
    this.chart = chart
    this.chartWidth = 0
    this.timeline = timeline
    this.config = config

    this.xScale = d3.scaleTime()

    this.yLeftScale = config.linearYScale ? d3.scaleLinear() : d3.scaleLog()

    this.yLeftScale.range([CHART_HEIGHT - this.config.chartMargin.bottom, this.config.chartMargin.top])

    this.currentXScale = this.xScale
    this.currentYScale = this.yLeftScale

    this.onZoomUpdated = onZoomUpdated

    this.zoom = d3zoom.zoom<SVGSVGElement, any>().on('zoom', event => this.onZoom(event))

    d3.select(this.chart).html(null)

    if (config.useCanvas) {
      const canvasHeight = Math.floor(CHART_HEIGHT * CANVAS_PIXEL_RATIO)

      const canvas = d3
        .select(this.chart)
        .append('canvas')
        .style('height', `${CHART_HEIGHT}px`)
        .attr('height', canvasHeight)
        .style('width', '10px')
        .attr('width', 10)
        .style('position', 'absolute')
        .style('pointer-events', 'none')
        .classed(ElementClass.canvas, true)

      const canvasContext = canvas.node()!.getContext('2d')!
      canvasContext.scale(CANVAS_PIXEL_RATIO, CANVAS_PIXEL_RATIO)

      const offScreenCanvas = document.createElement('canvas')
      offScreenCanvas.height = canvasHeight

      const offScreenContext = offScreenCanvas.getContext('2d')!
      offScreenContext.scale(CANVAS_PIXEL_RATIO, CANVAS_PIXEL_RATIO)

      this.canvasProps = {
        canvas,
        canvasContext,
        offScreenCanvas,
        offScreenContext,
      }
    }

    this.svgContainer = d3.select(this.chart).append('svg').attr('height', CHART_HEIGHT)

    const clipId = nanoid()
    this.clipUrl = `url(#${clipId})`

    const chartMargin = this.config.chartMargin

    this.svgClipPath = this.svgContainer
      .append('clipPath')
      .attr('id', clipId)
      .append('rect')
      .attr('x', chartMargin.left + 1)
      .attr('y', chartMargin.top)
      .attr('height', CHART_HEIGHT - chartMargin.top - chartMargin.bottom)

    this.xAxis = this.svgContainer.append('g').attr('transform', `translate(0,${CHART_HEIGHT - chartMargin.bottom})`)

    this.yAxisGrid = this.svgContainer
      .append('g')
      .attr('transform', `translate(${chartMargin.left},0)`)
      .attr('color', themeColors.disabled)

    this.yAxis = this.svgContainer.append('g').attr('transform', `translate(${chartMargin.left},0)`)

    this.mouseOverlay = this.svgContainer
      .append('rect')
      .attr('x', chartMargin.left + 1)
      .attr('y', chartMargin.top)
      .attr('height', CHART_HEIGHT - chartMargin.top - chartMargin.bottom)
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .on('mouseout', () => this.hideTooltip())
      .on('mousemove', event => this.mouseMove(event as MouseEvent))

    enablePageScrollForChartRenderer(this.svgContainer, this.zoom)

    d3.select(this.chart)
      .append('button')
      .classed(ElementClass.resetZoomButton, true)
      .classed(classes.zoomButton, true)
      .style('right', `${chartMargin.right + ZOOM_BUTTON_MARGIN}px`)
      .style('bottom', `${chartMargin.bottom + 2 * ZOOM_BUTTON_SPACING + ZOOM_BUTTON_MARGIN}px`)
      .html(resetZoomButton)
      .on('click', () => this.resetZoom())

    d3.select(this.chart)
      .append('button')
      .classed(ElementClass.zoomInButton, true)
      .classed(classes.zoomButton, true)
      .style('right', `${chartMargin.right + ZOOM_BUTTON_MARGIN}px`)
      .style('bottom', `${chartMargin.bottom + ZOOM_BUTTON_SPACING + ZOOM_BUTTON_MARGIN}px`)
      .html(zoomInButton)
      .on('click', () => this.zoom.scaleBy(this.svgContainer.transition().duration(250), 1.5))

    d3.select(this.chart)
      .append('button')
      .classed(ElementClass.zoomOutButton, true)
      .classed(classes.zoomButton, true)
      .style('right', `${chartMargin.right + ZOOM_BUTTON_MARGIN}px`)
      .style('bottom', `${chartMargin.bottom + ZOOM_BUTTON_MARGIN}px`)
      .html(zoomOutButton)
      .on('click', () => this.zoom.scaleBy(this.svgContainer.transition().duration(250), 0.5))

    this.tooltip = d3.select(this.chart).append('div').classed(classes.tooltip, true).style('pointer-events', 'none')

    this.tooltipPoints = this.svgContainer
      .append('g')
      .classed(ElementClass.tooltipPoints, true)
      .style('pointer-events', 'none')
      .attr('clip-path', this.clipUrl)

    this.tooltipArrow = d3
      .select(this.chart)
      .append('svg')
      .style('position', 'absolute')
      .attr('height', getTooltipConfig().arrowHeight)
      .style('pointer-events', 'none')

    this.tooltipArrow.append('polygon').classed(classes.tooltipArrow, true).classed(ElementClass.tooltipArrow, true)

    this.hideTooltip()
    this.sizeUpdated()
  }

  getDomain() {
    return {
      xMin: this.timeline.start,
      xMax: this.timeline.end,
      yMax: 0,
      yMin: 1,
    }
  }

  hideTooltip() {
    this.tooltipPoints.style('display', 'none')
    this.tooltip.style('display', 'none')
    this.tooltipArrow.style('display', 'none')
  }

  // eslint-disable-next-line class-methods-use-this
  getLabelForSeries(index: number) {
    return index.toString()
  }

  // eslint-disable-next-line class-methods-use-this
  getTooltipText(entry: TooltipEntry) {
    return `${entry.label}: ${entry.value}`
  }

  updateTooltip(mouseX: number) {
    const xScale = this.currentXScale
    const yScale = this.currentYScale

    const overlayX = Math.round(clamp(mouseX, 0, this.chartWidth))
    const timelineDate = xScale.invert(overlayX)
    const timelineMillis = timelineDate.valueOf()

    const dataValues = this.dataCurves.flatMap<TooltipEntry>((values, i) => {
      const dataPoint = values.find((v, i) => (i < values.length - 1 ? values[i + 1][0] >= timelineMillis : false))
      // Not found or before first entry or value is null
      const value = dataPoint?.[1]
      if (!dataPoint || typeof value !== 'number' || timelineMillis < values[0][0]) {
        return []
      }

      return {
        value,
        label: this.getLabelForSeries(i),
        index: i,
      }
    })

    const renderX = xScale(timelineMillis)
    const targetYLeft = Math.max(
      ...dataValues.filter(v => !this.inactiveSeries.includes(v.index)).map(v => v.value ?? Number.MIN_VALUE)
    )

    const renderYLeft = yScale(targetYLeft || 0)
    let renderY = Math.min(renderYLeft || Infinity)
    if (renderY === Infinity) {
      renderY = CHART_HEIGHT - this.config.chartMargin.bottom
    }

    if (renderX !== undefined && renderYLeft !== undefined) {
      if (!Number.isNaN(renderYLeft)) {
        this.tooltipPoints
          .selectAll<SVGCircleElement, SensorHistory>(classSelector(ElementClass.tooltipPoint))
          .attr('transform', (d, i) => `translate(${renderX},${yScale(dataValues[i]?.value || 0)})`)
          .style('display', (d, i) => (!dataValues[i] || this.inactiveSeries.includes(dataValues[i].index) ? 'none' : null))
      }

      const tooltipText = dataValues
        .flatMap(val => {
          if (this.inactiveSeries.includes(val.index)) {
            return []
          }

          const text = this.getTooltipText(val)

          return `<li style="color: ${this.getLineColor(val.index)}"><span>${text}</span></li>`
        })
        .join('')

      this.tooltip.html(getTooltipMarkup(format(timelineMillis, TOOLTIP_DATE_FORMAT), tooltipText, dataValues.length))

      const tooltipPosition = getTooltipPosition(renderX, renderY, this.tooltip.node()!, this.chartWidth, {
        chartMargin: this.config.chartMargin,
      })

      this.tooltip.style('left', `${tooltipPosition.box.x}px`).style('top', `${tooltipPosition.box.y}px`)

      const arrowPos = tooltipPosition.arrow
      const tipOffset = arrowPos.tipX - arrowPos.x
      this.tooltipArrow
        .attr('width', tooltipArrowBoxWidth(tipOffset))
        .style('left', `${Math.min(arrowPos.x, arrowPos.tipX)}px`)
        .style('top', `${arrowPos.y}px`)
        .select(classSelector(ElementClass.tooltipArrow))
        .attr('points', tooltipArrowPolygon(tipOffset))
    }
  }

  mouseMove(event: MouseEvent) {
    const overlay = this.mouseOverlay.node()
    if (this.dataCurves.length && overlay) {
      const mouseX = d3.pointer(event, overlay)[0]
      this.tooltipPoints.style('display', null)
      this.tooltip.style('display', null)
      this.tooltipArrow.style('display', null)
      this.updateTooltip(mouseX)
    }
  }

  setDataCurves(dataCurves: ChartEntry[][]) {
    this.dataCurves = dataCurves

    const domain = this.getDomain()

    // TODO: This is a silly workaround
    const yScale = this.yLeftScale
    const scale = yScale.domain([domain.yMin, domain.yMax]) as typeof yScale
    scale.nice()
    this.xScale.domain([domain.xMin, domain.xMax])
    this.zoom.scaleExtent([MIN_ZOOM, (domain.xMax - domain.xMin) / this.config.maxZoomMagicConstant])
    this.resetZoom()
  }

  updateSeriesVisibility() {
    this.svgContainer
      .selectAll(classSelector(ElementClass.dataCurve))
      .attr('display', (d, i) => (this.inactiveSeries.includes(i) ? 'none' : null))
  }

  setInactiveSeries(inactiveSeries: number[], render: boolean = true) {
    this.inactiveSeries = inactiveSeries
    if (render) {
      this.updateSeriesVisibility()
      this.update()
    }
  }

  setLabels(labels: ChartLabels, currentLanguage: Language) {
    this.labels = labels
    this.currentLanguage = currentLanguage

    d3.select(this.chart).select(classSelector(ElementClass.resetZoomButton)).attr('title', labels.resetZoom)

    d3.select(this.chart).select(classSelector(ElementClass.zoomInButton)).attr('title', labels.zoomIn)

    d3.select(this.chart).select(classSelector(ElementClass.zoomOutButton)).attr('title', labels.zoomOut)

    this.multiTimeFormat = createTimeMultiFormat(getD3Locale(currentLanguage))
  }

  setShowZoomButtons(showZoomButtons: boolean) {
    this.showZoomButtons = showZoomButtons
  }

  private getChartWidth() {
    const parent = this.chart.parentElement
    return parent ? parent.clientWidth : 0
  }

  // eslint-disable-next-line class-methods-use-this
  getColorIndex(index: number) {
    return index
  }

  getLineColor(index: number) {
    return getChartLineColorByIndex(this.getColorIndex(index))
  }

  sizeUpdated() {
    const newWidth = Math.max(0, this.getChartWidth())
    if (newWidth !== this.chartWidth) {
      this.chartWidth = newWidth

      const chartMargin = this.config.chartMargin
      const clipWidth = this.chartWidth - chartMargin.left - chartMargin.right - 1

      if (this.canvasProps) {
        const canvasWidth = Math.floor(this.chartWidth * CANVAS_PIXEL_RATIO)

        this.canvasProps.canvas.style('width', `${this.chartWidth}px`).attr('width', canvasWidth)

        this.canvasProps.canvasContext.beginPath()
        this.canvasProps.canvasContext.rect(
          chartMargin.left,
          chartMargin.top,
          this.chartWidth - chartMargin.left - chartMargin.right,
          CHART_HEIGHT - chartMargin.top - chartMargin.bottom
        )
        this.canvasProps.canvasContext.clip()

        this.canvasProps.offScreenCanvas.width = canvasWidth
      }

      this.svgContainer.attr('viewBox', [0, 0, this.chartWidth, CHART_HEIGHT].toString()).attr('width', this.chartWidth)

      this.xScale.range([chartMargin.left, this.chartWidth - chartMargin.right])

      this.svgClipPath.attr('width', this.chartWidth - chartMargin.left - chartMargin.right)

      const extent: Extent = [
        [chartMargin.left, chartMargin.top],
        [this.chartWidth - chartMargin.right, CHART_HEIGHT - chartMargin.bottom],
      ]

      this.zoom.translateExtent(extent).extent(extent)

      this.resetZoom()

      this.mouseOverlay.attr('width', clipWidth)
    }
  }

  private renderCurves(left: number = -Infinity, right: number = Infinity) {
    const xScale = this.currentXScale
    const yLeftScale = this.yLeftScale

    if (this.canvasProps) {
      const renderer = this.config.renderValueCurveCanvas || renderValueCurveCanvas
      const ctx = this.canvasProps.offScreenContext

      ctx.clearRect(0, 0, this.chartWidth + 1, CHART_HEIGHT)
      this.dataCurves.forEach((dc, i) => {
        if (!this.inactiveSeries.includes(i)) {
          const culledData = cullData(dc, left, right)

          ctx.beginPath()
          ctx.lineWidth = 1
          ctx.strokeStyle = this.getLineColor(i)
          renderer(culledData, xScale, yLeftScale, ctx)
          ctx.stroke()
        }
      })

      this.canvasProps.canvasContext.clearRect(0, 0, this.chartWidth, CHART_HEIGHT)
      this.canvasProps.canvasContext.drawImage(this.canvasProps.offScreenCanvas, 0, 0)
    } else {
      const renderer = this.config.renderValueCurveSVG || renderValueCurveSVG
      this.svgContainer
        .selectAll<SVGRectElement, [number, number][]>(classSelector(ElementClass.dataCurve))
        .attr('d', (d, i) => renderer(xScale, yLeftScale)(d))
    }
  }

  update() {
    const xScale = this.currentXScale
    const yLeftScale = this.currentYScale
    const left = xScale.invert(this.config.chartMargin.left).valueOf()
    const right = xScale.invert(this.chartWidth - this.config.chartMargin.right).valueOf()

    this.renderCurves(left, right)

    const domain = this.getDomain()

    this.svgContainer.selectAll<SVGPathElement, number>(classSelector(ElementClass.zeroLine)).attr('d', d => {
      const pos = `${xScale(domain.xMin)},${Math.ceil(yLeftScale(d)!) + 0.5}`
      const width = xScale(domain.xMax)!
      return `M${pos}H${width}`
    })

    let axisBottom = d3.axisBottom(xScale).ticks(Math.floor(this.chartWidth / X_PIXELS_PER_TICK))
    const timeFormat = this.multiTimeFormat
    if (timeFormat) {
      axisBottom = axisBottom.tickFormat(v => timeFormat(v.valueOf()))
    }

    this.xAxis.call(axisBottom)

    this.yAxis.call(d3.axisLeft(yLeftScale).tickFormat(v => this.config.formatYTick(v.valueOf())))

    d3.select(this.chart)
      .selectAll(classSelector(ElementClass.zoomInButton))
      .style('display', this.showZoomButtons ? 'inherit' : 'none')
    d3.select(this.chart)
      .selectAll(classSelector(ElementClass.zoomOutButton))
      .style('display', this.showZoomButtons ? 'inherit' : 'none')
    d3.select(this.chart)
      .selectAll(classSelector(ElementClass.resetZoomButton))
      .style('display', this.showZoomButtons ? 'inherit' : 'none')
  }

  resetZoom() {
    this.zoom.transform(this.svgContainer.transition().duration(0), d3.zoomIdentity)
  }

  onZoom(event: d3.D3ZoomEvent<SVGSVGElement, unknown>) {
    const transform: d3.ZoomTransform = event.transform
    this.currentXScale = transform.rescaleX(this.xScale)

    this.hideTooltip()
    this.update()

    if (this.onZoomUpdated) {
      const chartMargin = this.config.chartMargin
      const fromDate = this.currentXScale.invert(chartMargin.left).valueOf()
      const toDate = this.currentXScale.invert(this.chartWidth).valueOf()
      this.onZoomUpdated(fromDate, toDate, transform)
    }
  }

  setYDomain(minY: number | null, maxY: number | null) {
    const yScale = this.yLeftScale
    const domain = this.getDomain()
    yScale.domain([minY ?? domain.yMin, maxY ?? domain.yMax])
    this.update()
  }

  render() {
    const yLeftScale = this.currentYScale

    this.yAxis.call(d3.axisLeft(yLeftScale).tickFormat(v => this.config.formatYTick(v.valueOf())))

    this.yAxisGrid.call(
      d3
        .axisLeft(yLeftScale)
        .tickFormat(v => '')
        .ticks(4)
        .tickSize(-this.chartWidth + this.config.chartMargin.right + this.config.chartMargin.left)
    )

    const domain = this.getDomain()

    this.svgContainer
      .selectAll(classSelector(ElementClass.dataCurve))
      .data(this.dataCurves)
      .join('path')
      .classed(ElementClass.dataCurve, true)
      .attr('fill', 'none')
      .attr('stroke-width', 1.5)
      .attr('stroke', (d, i) => this.getLineColor(i))
      .style('pointer-events', 'none')
      .attr('clip-path', this.clipUrl)

    // Tooltip
    this.tooltipPoints
      .raise()
      .selectAll(classSelector(ElementClass.tooltipPoint))
      .data(this.dataCurves)
      .join('circle')
      .classed(ElementClass.tooltipPoint, true)
      .attr('r', TOOLTIP_POINT_RADIUS)

    this.svgContainer
      .selectAll(classSelector(ElementClass.zeroLine))
      .data(domain.yMin < 0 ? [0] : []) // Only add zero line if a value is below zero
      .join('path')
      .classed(ElementClass.zeroLine, true)
      .attr('stroke', themeColors.primary)
      .attr('stroke-width', 1)
      .attr('clip-path', this.clipUrl)

    this.update()
  }
}

export const getTooltipMarkup = (header: string, list: string, length: number) => {
  const columns = Math.ceil(length / 20)
  const css = `grid-template-columns: repeat(${columns}, auto)`
  return `<b>${header}</b><ul style="${css}">${list}</ul>`
}
