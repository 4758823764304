import type { ReactElement, KeyboardEventHandler, ReactNode } from 'react'
import { useMemo } from 'react'
import { styled } from '@mui/material/styles'

import type { ChartLegend } from 'src/Components/Chart/Legends/ChartLegend'

import { LegendButton } from 'src/Components/Chart/Legends/LegendButton'
import { LegendBand } from 'src/Components/Chart/Legends/LegendBand'
import { LegendsContainer } from 'src/Components/Chart/Legends/LegendsContainer'
import {
  ChartLegendControllers,
  moveInactiveDataIndexesBackward,
  moveInactiveDataIndexesForward,
} from 'src/Components/Chart/Legends/ChartLegendControllers'
import { getChartLineColorByIndex } from 'src/Utils/chart'
import themeColors from 'src/theme'

const StyledRootContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const StyledHeader = styled('div')`
  font-size: 14px;
  margin-bottom: 2px;
`

const StyledNoDataLegend = styled('div')`
  font-size: 14px;
  margin: 8px 0;
`

const StyledContentWrapper = styled('div')(
  props => `
  display: flex;
  justify-content: space-between; /* Distribute space between children */
  align-items: flex-start; /* Align items to the start */

   ${props.theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`
)

const StyledOwnContent = styled('div')`
  flex: 1; /* Take up remaining space */
`

const StyledChildren = styled('div')(
  props => `
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align to the right */

   ${props.theme.breakpoints.down('md')} {
    justify-content: flex-start; /* Align to the left on small screens */
    margin-top: 16px; /* Add some space above */
  }
`
)

type OwnProps = {
  legends: ChartLegend[]
  inactiveIds?: string[]
  inactiveIndexes?: number[]
  onInactiveIdsUpdated?: (inactive: string[]) => void
  onInactiveIndexesUpdated?: (inactive: number[]) => void
  legendBandWidth?: number
  emptyLegendsLabel?: string
  header?: string | ReactElement
  loading: boolean
  children?: ReactNode
}

const getIdsByIndexes = (legends: ChartLegend[], inactiveIndexes: number[] | undefined) =>
  inactiveIndexes?.map(index => legends[index]?.id) || []

const getIndexesByIds = (legends: ChartLegend[], inactiveIds: string[] | undefined) =>
  inactiveIds?.map(id => legends.findIndex(legend => legend.id === id)) || []

export const ChartLegendsList = ({
  legends,
  inactiveIds,
  inactiveIndexes,
  onInactiveIdsUpdated,
  onInactiveIndexesUpdated,
  legendBandWidth,
  emptyLegendsLabel,
  header,
  loading,
  children,
}: OwnProps) => {
  const legendsWithData = legends.filter(legend => !legend.isEmpty)
  const legendsWithoutData = legends.filter(legend => legend.isEmpty)
  const allIds = legendsWithData.map(({ id }) => id)
  const allIndexes = legendsWithData.map((_, i) => i)

  const realInactiveIds = useMemo(
    () => inactiveIds || getIdsByIndexes(legendsWithData, inactiveIndexes),
    [inactiveIds, inactiveIndexes, legendsWithData]
  )

  const realInactiveIndexes = useMemo(
    () => inactiveIndexes || getIndexesByIds(legendsWithData, inactiveIds),
    [inactiveIds, inactiveIndexes, legendsWithData]
  )

  const toggleId = (idToToggle: string) => {
    const newInactiveIds = allIds.filter(id => {
      const isInactive = realInactiveIds.includes(id)
      return idToToggle === id ? !isInactive : isInactive
    })
    const newInactiveIndexes = newInactiveIds.map(id => allIds.indexOf(id))
    onInactiveIndexesUpdated?.(newInactiveIndexes)
    onInactiveIdsUpdated?.(newInactiveIds)
  }

  const handleIndexUpdated = (inactiveIndexes: number[]) => {
    const inactiveIds = getIdsByIndexes(legendsWithData, inactiveIndexes)
    onInactiveIndexesUpdated?.(inactiveIndexes)
    onInactiveIdsUpdated?.(inactiveIds)
  }

  const handleRootContainerKeyDown: KeyboardEventHandler<HTMLDivElement> = event => {
    if (realInactiveIndexes.length === 0 || realInactiveIndexes.length === allIndexes.length) {
      return
    }
    switch (event.key) {
      case 'ArrowLeft':
        event.preventDefault()
        handleIndexUpdated(moveInactiveDataIndexesBackward(allIndexes, realInactiveIndexes))
        break
      case 'ArrowRight':
        event.preventDefault()
        handleIndexUpdated(moveInactiveDataIndexesForward(allIndexes, realInactiveIndexes))
        break
      default:
        break
    }
  }

  return (
    <StyledRootContainer onKeyDown={handleRootContainerKeyDown}>
      <StyledContentWrapper>
        <StyledOwnContent>
          {legendsWithData.length ? (
            <>
              {header && <StyledHeader>{header}</StyledHeader>}
              <ChartLegendControllers
                allDataIndexes={allIndexes}
                inactiveDataIndexes={realInactiveIndexes}
                onInactiveDataIndexesUpdated={handleIndexUpdated}
              />
            </>
          ) : undefined}
          <LegendsContainer>
            {legendsWithData.map(({ id, label, buttonTitle, color }, i) => {
              const active = !realInactiveIds.includes(id)
              return (
                <LegendButton key={id} type="button" active={active} title={buttonTitle} onClick={() => toggleId(id)}>
                  <LegendBand
                    color={active ? color || getChartLineColorByIndex(i) : themeColors.disabled}
                    bandWidth={legendBandWidth || 2}
                  />
                  <span>{label}</span>
                </LegendButton>
              )
            })}
          </LegendsContainer>
          {!!legendsWithoutData.length && !loading && (
            <StyledNoDataLegend>
              {emptyLegendsLabel && <span>{emptyLegendsLabel}:</span>} {legendsWithoutData.map(legend => legend.label).join(', ')}
            </StyledNoDataLegend>
          )}
        </StyledOwnContent>
        <StyledChildren>{children}</StyledChildren>
      </StyledContentWrapper>
    </StyledRootContainer>
  )
}
