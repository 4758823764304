import React, { useRef, useEffect, useState, useMemo } from 'react'
import { styled } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'

import { Alert } from 'src/Components/Alert'
import { PointMachineHeatRenderer } from './pointMachineHeatRenderer'

import type { ChartLabels } from 'src/Types/ChartTypes'
import type { DatePeriod } from 'src/Types/DatePeriod'
import type { PointMachineHeatChartAsset, PointMachineHeatSensorStatus } from 'src/Types/PointMachineHeatSensorStatus'
import { useUserProfileStore } from 'src/Store/userProfile'
import { useChartStyles } from 'src/Components/Chart/chartStyles'
import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'
import { usePointMachineHeatSensorData } from 'src/Hooks/NetworkData/usePointMachineHeatSensorData'
import { useLanguage } from 'src/Hooks/useLanguage'
import { ChartLegendsList } from 'src/Components/Chart/Legends/ChartLegendsList'
import { usePointerMachineHeatSensorLegends } from 'src/Components/Chart/Legends/usePointerMachineHeatSensorLegends'
import { useChartResizeBehaviour } from 'src/Hooks/useChartResizeBehaviour'

const StyledContainer = styled('div')(
  props => `
  margin: 16px;
  ${props.theme.breakpoints.down('md')} {
    margin: 4px;
  }
`
)

const StyledChart = styled('div')`
  position: relative;
`

type OwnProps = {
  switchBaneDataId: string
  isSequence: boolean
  sensorStatus: PointMachineHeatSensorStatus
  datePeriod: DatePeriod
}

export const PointMachineHeatChart = ({ switchBaneDataId, sensorStatus, isSequence, datePeriod }: OwnProps) => {
  const { t, currentLanguage } = useLanguage()
  const { classes: chartClasses } = useChartStyles()
  const chartRef = useRef<HTMLDivElement>(null)
  const hideChartZoomButtons = useUserProfileStore(state => state.settings.settings.hideChartZoomButtons)
  const [renderer, setRenderer] = useState<PointMachineHeatRenderer>()
  const [inactivePointMachines, setInactivePointMachines] = useState<number[]>([])

  const { sensorData, status } = usePointMachineHeatSensorData(switchBaneDataId, isSequence, datePeriod)

  const assets = useMemo<PointMachineHeatChartAsset[]>(() => {
    if (!sensorStatus) {
      return []
    }

    if (isSequence) {
      return [
        {
          name: sensorStatus.parent.name,
          id: sensorStatus.parent.id,
        },
      ]
    }

    return sensorStatus.assets.map(asset => ({ name: asset.parent.name, id: asset.parent.id }))
  }, [sensorStatus, isSequence])

  const inactivePointMachinesUpdated = (inactivePointMachines: number[]) => {
    setInactivePointMachines(inactivePointMachines)
    if (renderer) {
      renderer.setInactiveSeries(inactivePointMachines)
    }
  }

  useChartResizeBehaviour(renderer)

  const { fromDate, toDate } = useMemo(() => getDateRange(datePeriod), [datePeriod])

  useEffect(() => {
    const chart = chartRef.current

    if (chart && sensorData) {
      const labels: ChartLabels = {
        zoomIn: t('general.zoomIn'),
        zoomOut: t('general.zoomOut'),
        resetZoom: t('general.resetZoom'),
      }

      const chartRenderer = new PointMachineHeatRenderer({
        chart,
        timeline: {
          start: fromDate,
          end: toDate,
        },
        classes: {
          tooltip: chartClasses.tooltip,
          tooltipArrow: chartClasses.tooltipArrow,
          zoomButton: chartClasses.zoomButton,
        },
      })
      setRenderer(chartRenderer)

      chartRenderer.setData(sensorData)
      chartRenderer.setInactiveSeries(inactivePointMachines, false)
      chartRenderer.setLabels(labels, currentLanguage)
      chartRenderer.setAssets(assets)
      chartRenderer.setShowZoomButtons(!hideChartZoomButtons)
      chartRenderer.render()
    }
    // eslint-disable-next-line max-len
  }, [sensorData, currentLanguage, chartClasses, fromDate, toDate, t]) // eslint-disable-line react-hooks/exhaustive-deps

  const sensorHistoryLegends = usePointerMachineHeatSensorLegends(sensorData, assets, inactivePointMachines)

  if (status === 'idle') {
    return null
  }

  if (status === 'error') {
    return (
      <Alert severity="error" margin={16}>
        {t('switches.pointMachineHeat.chart.fetchError')}
      </Alert>
    )
  }

  return (
    <>
      {status === 'loading' && (
        <StyledContainer>
          <CircularProgress color="inherit" size={15} /> {t('switches.pointMachineHeat.chart.loading')}
        </StyledContainer>
      )}
      {!sensorData?.length && status === 'success' && (
        <StyledContainer>{t('switches.pointMachineHeat.chart.noneFound')}</StyledContainer>
      )}
      {!!sensorData?.length && (
        <StyledContainer>
          <StyledChart ref={chartRef} />
          <ChartLegendsList
            legends={sensorHistoryLegends}
            inactiveIndexes={inactivePointMachines}
            onInactiveIndexesUpdated={inactivePointMachinesUpdated}
            loading={status === 'loading'}
          />
        </StyledContainer>
      )}
    </>
  )
}
